import React from "react";
import { FaPhoneAlt, FaMapMarkerAlt, FaEnvelope, FaYelp } from "react-icons/fa";
import { motion } from "framer-motion";
import bg1 from "../assets/bg3.jpg";
import { FaShareNodes } from "react-icons/fa6";
import { SiNextdoor } from "react-icons/si";

const Contact = () => {
  const images = [bg1];
  const bgNum = Math.floor(Math.random() * 1);
  const bgImage = images[bgNum];

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "PBM Construction ",
          text: "Check out PBM Construction for all your construction needs!",
          url: window.location.href,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      alert("Web Share API is not supported in your browser.");
    }
  };

  return (
    <div className="text-center">
      <motion.div
        className="relative h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center flex-col text-center">
          <h1 className="text-[#17b257] text-4xl font-bold mb-4 mt-12">
            Contact Us
          </h1>
          <motion.div
            whileHover={{
              scale: 1.1,
              translateZ: 50,
              perspective: 1000,
              boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
              transition: { duration: 0.2 },
            }}
            className="inline-block mb-4"
          >
            <a
              href="tel:+14255020236"
              className="text-white bg-[#17b257] px-4 py-2 rounded font-bold"
            >
              <span>📞</span>
              <span>(425) 502-0236</span>
            </a>
          </motion.div>
        </div>
      </motion.div>
      <div className="container mx-auto px-4 py-8">
        <h2 className="text-2xl text-center underline underline-offset-2 font-bold mb-4">
          Contact Me:
        </h2>
        <div className="flex flex-col items-center space-y-4 mb-8">
          <motion.div
            className="flex items-center space-x-2"
            whileHover={{
              scale: 1.1,
              transition: { duration: 0.3 },
            }}
          >
            <FaPhoneAlt
              className="text-black"
              style={{ verticalAlign: "middle" }}
            />
            <a
              href="tel:+14255020236"
              className="bg-[#17b257] text-white px-4 py-2 rounded font-bold flex items-center space-x-2"
              style={{ perspective: "500px" }}
            >
              425-502-0236
            </a>
          </motion.div>
          <motion.div
            className="flex items-center space-x-2"
            whileHover={{
              scale: 1.1,
              transition: { duration: 0.3 },
            }}
          >
            <FaShareNodes
              className="text-black"
              style={{ verticalAlign: "middle" }}
            />
            <a
              href="#"
              onClick={handleShare}
              className="bg-[#17b257] text-white px-4 py-2 rounded font-bold flex items-center space-x-2"
              style={{ perspective: "500px" }}
            >
              Share this website
            </a>
          </motion.div>
          <motion.div
            className="flex items-center space-x-2"
            whileHover={{
              scale: 1.1,
              transition: { duration: 0.3 },
            }}
          >
            <FaEnvelope
              className="text-black"
              style={{ verticalAlign: "middle" }}
            />
            <a
              href="mailto:Constructionpbm@gmail.com"
              className="bg-[#17b257] text-white px-4 py-2 rounded font-bold flex items-center space-x-2"
              style={{ perspective: "500px" }}
            >
              Constructionpbm@gmail.com
            </a>
          </motion.div>
        </div>
        <div className="flex justify-center space-x-4">
          <a
            href="https://nextdoor.com/pages/cedar-carpentry-bellevue-wa/"
            target="_blank"
            rel="noreferrer"
            className="text-[#17b257] hover:text-black transition duration-300"
          >
            <SiNextdoor size={60} />
          </a>
        </div>
        <div className="flex flex-col items-center space-y-4">
          <h2 className="text-2xl text-center underline underline-offset-2 font-bold mb-4">
            Hours of Operation:
          </h2>
          <p className="text-xl text-center">
            Monday - Friday: 7:00 AM - 6:00 PM
          </p>
          <p className="text-xl text-center">Saturday: 9:00 AM - 5:00 PM</p>
          <p className="text-xl text-center">Sunday: Closed</p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
