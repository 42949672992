import { React, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import logo from '../assets/nobg.png';

const Header = () => {
    const location = useLocation();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [scrolling, setScrolling] = useState(false);

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    }

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setScrolling(true);
            } else {
                setScrolling(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header
            className={`top-0 left-0 right-0 fixed z-50 transition-colors duration-300 ${
                scrolling ? 'bg-white shadow-lg' : 'bg-transparent'
            }`}
        >
            <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                <Link to="/" className="flex items-center">
                    <img src={logo} alt="PBM Construction Logo" className="h-12 w-12 mr-2 rounded-none" />
                    <span className="text-2xl font-bold text-[#17b257] drop-shadow-md">PBM Construction</span>
                </Link>
                <nav className="hidden md:flex space-x-4 text-[#17b257]">
                    <Link to="/" className={`hover:underline ${location.pathname === '/' && 'underline drop-shadow-md'}`} style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Home</Link>
                    <Link to="/services" className={`hover:underline ${location.pathname === '/services' && 'underline drop-shadow-md'}`} style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Services</Link>
                    <Link to="/about" className={`hover:underline ${location.pathname === '/about' && 'underline drop-shadow-md'}`} style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>About</Link>
                    <Link to="/contact" className={`hover:underline ${location.pathname === '/contact' && 'underline drop-shadow-md'}`} style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Contact</Link>
                </nav>
                <button className="md:hidden text-[#17b257]" onClick={toggleDrawer}>
                    <FaBars />
                </button>
            </div>
            {drawerOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-75 z-50 md:hidden">
                    <div className="absolute top-0 right-0 p-4">
                        <button onClick={toggleDrawer}>
                            <FaTimes className="text-white" />
                        </button>
                    </div>
                    <div className="flex flex-col items-center justify-center h-full space-y-4 text-white">
                        <Link to="/" onClick={toggleDrawer} className={`text-xl ${location.pathname === '/' && 'underline'}`} style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Home</Link>
                        <Link to="/services" onClick={toggleDrawer} className={`text-xl ${location.pathname === '/services' && 'underline'}`} style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Services</Link>
                        <Link to="/about" onClick={toggleDrawer} className={`text-xl ${location.pathname === '/about' && 'underline'}`} style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>About</Link>
                        <Link to="/contact" onClick={toggleDrawer} className={`text-xl ${location.pathname === '/contact' && 'underline'}`} style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Contact</Link>
                    </div>
                </div>
            )}
        </header>
    )
}

export default Header;
