import React from 'react';
import { motion } from 'framer-motion';
import bg3 from '../assets/bg1.jpeg';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import drywall from '../assets/services/drywall.jpg';
import painting from '../assets/services/painting.jpg';
import carpentry from '../assets/services/carpentry.jpg';
import water from '../assets/services/water.jpg';
import doors from '../assets/services/doors.jpg';
import floortile from '../assets/services/floortile.jpg';
import handyman from '../assets/services/handyman.jpg';
import ada from '../assets/services/ada.jpg';


const Services = () => {
  const images = [bg3];
  const bgNum = Math.floor(Math.random() * 1);
  const bgImage = images[bgNum];

  return (
    <div className="text-center" style={{ fontFamily: 'DM Sans' }}>
      <motion.div
        className="relative h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center flex-col">
          <h1 className="text-[#17b257] text-4xl font-bold mb-4">Our Services</h1>
          <motion.div
            whileHover={{ scale: 1.1, translateZ: 50, perspective: 1000, boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)", transition: { duration: 0.2 } }}
            className="inline-block mb-4"
          >
            <a href="tel:+14255020236" className="text-white bg-[#17b257] px-4 py-2 rounded font-bold">
              <span>📞</span><span>Free Estimate at (425) 502-0236</span>
            </a>
          </motion.div>
        </div>
      </motion.div><div className="max-w-4xl mx-auto text-white px-4 pt-4 pb-10">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 pt-4">
          <ServiceCard title="General Contracting" backgroundImage={bg3} />
          <ServiceCard title="Drywall Repairs/Additions" backgroundImage={drywall} />
          <ServiceCard title="Painting" backgroundImage={painting} />
          <ServiceCard title="Carpentry" backgroundImage={carpentry} />
          <ServiceCard title="Water Loss Mitigation" backgroundImage={water} />
          <ServiceCard title="Doors and Trim" backgroundImage={doors} />
          <ServiceCard title="Tile Work" backgroundImage={floortile} />
          <ServiceCard title="Handyman Type Services" backgroundImage={handyman} />
          <ServiceCard title="ADA Compliant Services" backgroundImage={ada} />
        </div>
      </div>
    </div>
  );
};

const ServiceCard = React.forwardRef(({ title, backgroundImage }, ref) => {
  const { ref: inViewRef, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const cardStyle = {
    backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  return (
    <Link to="/contact" className="block" ref={inViewRef}>
      <motion.div
        className="bg-white bg-opacity-25 rounded-md p-4 flex flex-col items-center justify-center text-center hover:bg-opacity-50 transition duration-300 h-40 w-full"
        style={cardStyle}
        initial={{ opacity: 0, y: 50 }}
        animate={inView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.5 }}
        whileHover={{ scale: 1.1, translateZ: 50, perspective: 1000, boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)", transition: { duration: 0.01 } }}
      >
        <h2 className="text-3xl font-semibold mb-2">{title}</h2>
      </motion.div>
    </Link>

  );
}
);



export default Services;
