import React from "react";
import { useScroll, useTransform, motion } from "framer-motion";
import { Link } from "react-router-dom";
import { FaClock, FaDollarSign } from "react-icons/fa";
import { PiCertificateFill } from "react-icons/pi";
import { BsTools } from "react-icons/bs";
import bg2 from "../assets/bg2.jpg";
import bg1 from "../assets/bg1.jpeg";
import bg3 from "../assets/bg3.jpg";

const Home = () => {
  const { scrollY } = useScroll();

  const y1 = useTransform(scrollY, [0, 1000], [0, 0], { clamp: false });
  const y2 = useTransform(scrollY, [0, 1000], [0, 0], { clamp: false });

  return (
    <div className="text-center" style={{ fontFamily: "DM Sans" }}>
      <motion.div
        className="relative h-screen bg-cover bg-center"
        style={{ backgroundImage: `url(${bg2})`, y: y1 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center sm:items-start sm:pl-14 sm:pr-10 sm:py-20">
          <h1 className="text-[#17b257] text-4xl md:text-6xl font-bold text-center sm:text-left mr-2 sm:pr-3 pb-2">
            PBM Construction
          </h1>
          <h3 className="text-[#17b257] text-xl md:text-2xl pr-2 mb-4 text-center sm:text-left">
            Call us for a free estimate today!
          </h3>
          <motion.div
            whileHover={{
              scale: 1.1,
              translateZ: 50,
              perspective: 1000,
              boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
              transition: { duration: 0.2 },
            }}
            className="inline-block mb-4 text-center sm:text-left"
          >
            <a
              href="tel:+14255020236"
              className="text-white bg-[#17b257] px-4 py-2 rounded font-bold flex items-center space-x-1 text-lg md:text-xl"
            >
              <span>📞</span>
              <span>(425) 502 0236</span>
            </a>
          </motion.div>
        </div>
      </motion.div>

      <motion.div
        className="relative sm:h-[26rem] h-[38rem]  bg-cover bg-center"
        style={{ backgroundImage: `url(${bg1})`, y: y2 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center pb-6">
          <h3 className="text-[#17b257] text-2xl md:text-3xl font-bold px-4 mt-8 text-center underline underline-offset-2">
            Why Choose PBM?
          </h3>
          <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 px-4">
            <motion.div
              className="bg-black p-6 rounded-lg shadow-lg flex items-center"
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
            >
              <FaClock className="text-[#17b257] w-6 h-6 md:w-8 md:h-8 mr-4" />
              <p className="text-[#17b257] text-sm md:text-lg">
                Quick, but high quality work.
              </p>
            </motion.div>
            <motion.div
              className="bg-black p-6 rounded-lg shadow-lg flex items-center"
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <PiCertificateFill className="text-[#17b257] w-6 h-6 md:w-8 md:h-8 mr-4" />
              <p className="text-[#17b257] text-sm md:text-lg">
                Over 10 years of experience
              </p>
            </motion.div>
            <motion.div
              className="bg-black p-6 rounded-lg shadow-lg flex items-center"
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <FaDollarSign className="text-[#17b257] w-6 h-6 md:w-8 md:h-8 mr-4" />
              <p className="text-[#17b257] text-sm md:text-lg">
                The best prices.
              </p>
            </motion.div>
            <motion.div
              className="bg-black p-6 rounded-lg shadow-lg flex items-center"
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
              <BsTools className="text-[#17b257] w-6 h-6 md:w-8 md:h-8 mr-4" />
              <p className="text-[#17b257] text-sm md:text-lg">
                We simply are the best you can get!
              </p>
            </motion.div>
          </div>
          <div className="mt-8 space-x-4 flex flex-wrap justify-center">
            <motion.div
              whileHover={{
                scale: 1.1,
                translateZ: 50,
                perspective: 1000,
                boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
                transition: { duration: 0.2 },
              }}
              className="inline-block mb-4"
            >
              <Link
                to="/services"
                className="text-white bg-[#17b257] px-4 py-2 rounded font-bold text-lg md:text-xl"
              >
                <span>↗️</span>View Services
              </Link>
            </motion.div>
          </div>
        </div>
      </motion.div>
      <div className="mt-8 flex flex-wrap justify-center md:justify-between items-start">
        <div className="w-full md:w-1/2 text-left sm:space-y-1 text-[#120309] text-2xl pb-6 px-8">
          <h2 className="text-[#17b257] text-3xl textfont-bold font-bold pb-3">
            We offer the following services:
          </h2>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            • General Contracting
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            • Drywall Repairs/Additions
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            • Painting
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            • Carpentry
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.8 }}
          >
            • Water Loss Mitigation
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 1.0 }}
          >
            • Doors & Trim
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 1.2 }}
          >
            • Tile Work
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 1.4 }}
          >
            • Handyman Services
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 1.6 }}
          >
            • ADA Compliant Services
          </motion.div>
        </div>
        <div className="w-full md:w-1/2 flex justify-center md:justify-end pb-4 px-4 sm:px-0">
          <img
            src={bg3}
            alt="Service"
            className="max-w-full h-auto object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
