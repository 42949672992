import React, { useState } from "react";
import { motion } from "framer-motion";
import bg1 from "../assets/bg1.jpeg";
import bg2 from "../assets/bg3.jpg";

const images = [bg1];

const About = () => {
  const bgimages = [bg1];
  const bgNum = Math.floor(Math.random() * bgimages.length);
  const bgImage = bgimages[bgNum];

  const [selectedImage, setSelectedImage] = useState(null);

  const openImage = (index) => {
    setSelectedImage(index);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <motion.div
        className="relative h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${bg1})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center flex-col">
          <h1 className="text-[#17b257] text-4xl font-bold mb-4 px-6">
            About Us
          </h1>
          <motion.div
            whileHover={{
              scale: 1.1,
              translateZ: 50,
              perspective: 1000,
              boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
              transition: { duration: 0.2 },
            }}
            className="inline-block mb-4"
          >
            <a
              href="tel:+14255020236"
              className="text-white bg-[#17b257] px-4 py-2 rounded font-bold"
            >
              <span>📞</span>
              <span>Call us at (425) 502-0236 to Book</span>
            </a>
          </motion.div>
        </div>
      </motion.div>
      <div className="container mx-auto px-4 py-8 flex flex-col md:flex-row items-start">
        <div className="w-full md:w-1/2">
          <img
            src={bg2}
            alt="stockimageused"
            className="rounded shadow-md w-full"
          />
        </div>
        <div className="w-full md:w-1/2 text-[#17b257] text-xl mt-4 md:mt-0 md:pl-8 text-left">
          <p className="pb-5">
            <span className="text-[#120309] underline underline-offset-2 font-bold">
              About PBM Construction:
            </span>{" "}
            A leader in construction within the Seattle area, PBM Construction has 
            been delivering quality workmanship for over 10 years. With outstanding testimonials from customers and a reputation for excellence,
            we are the go-to choice for all your construction needs.
            <br />
            <br />
            Just read David's testimonial:
            <div className="italic">"Victor was fantastic! My family was in a really big pinch. I’m a struggling father with 3 little ones, my house had a leak that caused drywall damage. Victor came in assessed and within a 24 hour turnaround  he repaired my drywall  and even repainted the walls! Victor was a class act! 10/10 recommend."</div>
            <br />
            <br />
            So what are you waiting for? Call us today to book your free estimate!
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
